import supportedBrowsers from '@util/supported-browsers';
import localStorageTtl from '@util/local-storage-ttl';

export default () => {
  if (
    !window.IPSO_GLOBALS.IS_API &&
    !supportedBrowsers.test(navigator.userAgent)
  ) {
    const hasShownSupportedBrowserNotification = localStorageTtl.get(
      'has_shown_supported_browser_notification',
    );

    if (!hasShownSupportedBrowserNotification) {
      window.IPSO_GLOBALS.addToastMessage({
        type: 'warning',
        messages: [
          "Attention, vous visitez notre site depuis un navigateur non supporté. Pour une meilleure expérience, nous vous conseillons d'utiliser une version récente de Firefox, Edge, Chrome, ou Safari.",
        ],
        action: {
          text: "j'ai compris",
          callback: () => {
            localStorageTtl.set(
              'has_shown_supported_browser_notification',
              true,
              {
                days: 1,
              },
            );
          },
        },
      });
    }
  }
};
