import axios from 'axios';

import { IS_BROWSER } from '@util/globals';
import Sentry from '@util/sentry';

const SENTRY_IGNORED_ERRORS = [0];

const INIT_SUCCESS_MESSAGE = '[api] initialised';
const INIT_WARNING_MESSAGE = "[api] You haven't initialised the API";

const GET_OAUTH_TOKEN = 'frontend-api:auth:oauth:token';
const REFRESH_OAUTH_TOKEN = 'frontend-api:auth:oauth:refresh-token';

export const ERROR_API_NOT_INIT = '[api] not init';
export const ERROR_API_NO_SERVER_DATA = '[api] server returned no data';

const defaultAxiosConfig = {
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  withCredentials: false,
};

export const Api = () => ({
  isInit: false,
  isDebug: false,

  sessionId: '',
  accessToken: '',
  refreshToken: '',

  init({
    serverUrlBase = '',
    publicHostName = '',
    serverSetHttpHeaders = {},
    sessionId = '',
    isDebug = false,
  }) {
    this.serverSetHttpHeaders = serverSetHttpHeaders;

    const headers = {};

    if (!IS_BROWSER) {
      headers['Host'] = publicHostName;
    }

    this.axios = axios.create({
      ...defaultAxiosConfig,
      baseURL: serverUrlBase,
      headers,
    });

    if (isDebug) {
      this.axios.interceptors.request.use((req) => {
        console.log(`${req.method} ${req.url}`);

        return req;
      });
    }

    this.isDebug = isDebug;

    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const code = error.response?.data?.code;

        if (error.response?.status === 403 && code === 'AUTH_OAUTH_REQUIRED') {
          const originalRequest = error.config;

          const [refreshOk, refreshError] = await this.refreshOauthToken();

          if (refreshError) {
            return Promise.reject(refreshError);
          }

          if (refreshOk) {
            return this.axios(originalRequest);
          }
        }

        return Promise.reject(error);
      },
    );

    this.sessionId = sessionId;
    this.isInit = true;

    if (isDebug) {
      console.info(INIT_SUCCESS_MESSAGE);
    }
  },

  initOauthToken: async function () {
    const [tokenData, error] = await this.getJsonByDjangoViewName({
      viewName: GET_OAUTH_TOKEN,
      params: {
        session_id: this.sessionId,
      },
    });
    if (error) {
      return [undefined, error];
    }

    this.setOauthToken(tokenData);

    this.requestInterceptor = this.axios.interceptors.request.use(
      (config) => {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${this.accessToken}`,
        };

        return config;
      },
      (error) => Promise.reject(error),
    );

    this.hasOauth = true;

    return [true, undefined];
  },

  setOauthToken: function ({ access_token, refresh_token }) {
    this.accessToken = access_token;
    this.refreshToken = refresh_token;
  },

  refreshOauthToken: async function () {
    const [tokenData, error] = await this.getJsonByDjangoViewName({
      viewName: REFRESH_OAUTH_TOKEN,
      params: {
        session_id: this.sessionId,
        refresh_token: this.refreshToken,
      },
    });

    if (error) {
      return [undefined, error];
    }

    this.setOauthToken(tokenData);

    return [true, undefined];
  },

  getInitial: function ({ path, ...requestConfig }) {
    return this.xhr(
      axios.create(defaultAxiosConfig),
      {
        url: path,
        ...requestConfig,
      },
      true,
    );
  },

  xhr: async function (
    axiosInstance = this.axios,
    requestConfig,
    ignoreInit = false,
    shouldSendServerSetHttpHeaders = true,
  ) {
    if (!this.isInit && ignoreInit === false) {
      if (this.isDebug) {
        console.warn(INIT_WARNING_MESSAGE);
      }

      return [undefined, new Error(ERROR_API_NOT_INIT)];
    }

    if (shouldSendServerSetHttpHeaders) {
      requestConfig.headers = {
        ...requestConfig.headers,
        ...this.serverSetHttpHeaders,
      };
    }

    try {
      const response = await axiosInstance(requestConfig);
      if (!response.data) {
        throw new Error(ERROR_API_NO_SERVER_DATA);
      }

      return [response.data, undefined];
    } catch (error) {
      // log unexpcted (i.e. missing custom error code) errors to Sentry
      if (
        !SENTRY_IGNORED_ERRORS.includes(error.request?.status) &&
        !error.response?.data?.code
      ) {
        Sentry.getInstance().setExtra('error', error);
        Sentry.getInstance().setExtra('request', error.request);
        Sentry.getInstance().setExtra('response', error.response);
        Sentry.getInstance().captureMessage(
          `unexpected error ${error.request?.status}`,
        );
      }

      return [undefined, error];
    }
  },

  get: function ({
    path = '',
    headers = {},
    params = {},
    shouldSendServerSetHttpHeaders = true,
    ...otherOptions
  }) {
    return this.xhr(
      this.axios,
      {
        url: path,
        method: 'GET',
        params,
        headers,
        ...otherOptions,
      },
      false,
      shouldSendServerSetHttpHeaders,
    );
  },

  put: async function ({
    path,
    headers = {},
    params = {},
    data,
    ...otherOptions
  }) {
    return this.xhr(this.axios, {
      url: path,
      method: 'PUT',
      params,
      data,
      headers,
      ...otherOptions,
    });
  },

  post: async function ({
    path,
    headers = {},
    params = {},
    data,
    shouldSendServerSetHttpHeaders = true,
    ...otherOptions
  }) {
    return this.xhr(
      this.axios,
      {
        url: path,
        method: 'POST',
        params,
        data,
        headers,
        ...otherOptions,
      },
      false,
      shouldSendServerSetHttpHeaders,
    );
  },

  delete: async function ({
    path,
    headers = {},
    params = {},
    shouldSendServerSetHttpHeaders = true,
    ...otherOptions
  }) {
    return this.xhr(
      this.axios,
      {
        url: path,
        method: 'DELETE',
        params,
        headers,
        ...otherOptions,
      },
      false,
      shouldSendServerSetHttpHeaders,
    );
  },

  getDjangoUrl: async function ({ viewName, params, ...rest }) {
    const RESOURCE_REVERSE_URL_PREFIX = `${
      window.IPSO_GLOBALS.IS_USING_API_DEBUG_PATH ? '/api' : ''
    }/frontend-api/utils/reverse-url/`;

    const path = RESOURCE_REVERSE_URL_PREFIX + viewName + '/';

    const [{ url } = {}, error] = await this.get({ path, params, ...rest });

    if (error) {
      return [undefined, error];
    }

    return [url, undefined];
  },

  getJsonByDjangoViewName: async function ({
    viewName,
    headers,
    params,
    queryParams,
    ...otherOptions
  }) {
    const [path, urlError] = await this.getDjangoUrl({
      viewName,
      params,
      headers,
    });

    if (urlError) return [undefined, urlError];

    const [json, getError] = await this.get({
      path,
      params: queryParams,
      headers,
      ...otherOptions,
    });

    if (getError) return [undefined, getError];

    return [json, undefined];
  },

  putJsonByDjangoViewName: async function ({
    viewName,
    headers,
    params,
    queryParams,
    data,
    ...otherOptions
  }) {
    const [path, urlError] = await this.getDjangoUrl({
      viewName,
      params,
      headers,
    });

    if (urlError) return [undefined, urlError];

    const [response, putError] = await this.put({
      path,
      params: queryParams,
      headers,
      data,
      ...otherOptions,
    });

    if (putError) return [undefined, putError];

    return [response, undefined];
  },

  postJsonByDjangoViewName: async function ({
    viewName,
    headers,
    params,
    queryParams,
    data,
    ...otherOptions
  }) {
    const [path, urlError] = await this.getDjangoUrl({
      viewName,
      params,
      headers,
    });

    if (urlError) return [undefined, urlError];

    const [response, postError] = await this.post({
      path,
      headers,
      data,
      params: queryParams,
      ...otherOptions,
    });

    if (postError) return [undefined, postError];

    return [response, undefined];
  },

  deleteByDjangoViewName: async function ({
    viewName,
    headers,
    params,
    ...otherOptions
  }) {
    const [path, urlError] = await this.getDjangoUrl({
      viewName,
      params,
      headers,
    });

    if (urlError) return [undefined, urlError];

    const [response, deleteError] = await this.delete({
      path,
      headers,
      ...otherOptions,
    });

    if (deleteError) return [undefined, deleteError];

    return [response, undefined];
  },
});

export default Api();
