import { colors } from '@util/theme';

const baseGeometryColor = colors.gray.light;
const baseTextColor = colors.gray.darker;
const baseLandscapeColor = colors.gray.light;
const zoomedPoiColor = colors.orange.light;

const parkColor = colors.green.light;
const waterColor = colors.aqua.DEFAULT;
const roadColor = colors.gray.DEFAULT;
const countryBorderColor = colors.white;

const ZOOM_LEVEL_BREAKPOINTS = { Global: 0, Zone: 10, MedicalCenter: 14 };

const baseMapStyle = [
  {
    elementType: 'geometry',
    stylers: [{ color: baseGeometryColor }],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ color: baseTextColor }],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry.stroke',
    stylers: [{ color: countryBorderColor }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: waterColor }],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [{ color: baseLandscapeColor }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },

  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: parkColor }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: roadColor }],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
];

const cumulativeMapStyleChangesByIncreasingZoomLevel = [
  {
    level: ZOOM_LEVEL_BREAKPOINTS.Zone,
    styleChanges: [],
  },
  {
    level: ZOOM_LEVEL_BREAKPOINTS.MedicalCenter,
    styleChanges: [
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: zoomedPoiColor }],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: parkColor }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'on' }],
      },
      {
        featureType: 'poi.business',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
    ],
  },
];

function getMapStyleForZoomLevel(zoomLevel) {
  const styleChanges = cumulativeMapStyleChangesByIncreasingZoomLevel
    .filter(({ level }) => zoomLevel >= level)
    .map(({ styleChanges }) => styleChanges)
    .flat(1);

  return [...baseMapStyle, ...styleChanges];
}

function getBreakpointForZoomLevel(zoomLevel) {
  const [breakpointId] = Object.entries(ZOOM_LEVEL_BREAKPOINTS)
    .filter(([, level]) => zoomLevel >= level)
    .sort(([, levelA], [, levelB]) => levelB - levelA)[0];
  return breakpointId;
}

export function getMapStyleIdForZoomLevel(zoomLevel) {
  return getBreakpointForZoomLevel(zoomLevel);
}

const mapStylesByZoomBreakpoint = Object.fromEntries(
  Object.entries(ZOOM_LEVEL_BREAKPOINTS).map(([breakpointName, zoomLevel]) => [
    breakpointName,
    getMapStyleForZoomLevel(zoomLevel),
  ]),
);

export const mapStyles = {
  default: baseMapStyle,
  ...mapStylesByZoomBreakpoint,
};
