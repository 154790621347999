<template>
  <div class="fixed bottom-0 right-0 z-1">
    <transition-group
      tag="div"
      :css="false"
      class="flex max-w-xl flex-col items-end space-y-2 p-4"
      :hidden="!isShowing"
      @enter="enter"
      @leave="leave"
    >
      <div
        v-for="toast in toasts"
        :key="toast.id"
        class="h-0 overflow-hidden rounded-lg bg-white opacity-0 shadow"
      >
        <banner-message
          :type="toast.type"
          class="relative"
          text-class="lg:pr-6 space-y-1 pt-1 lg:pt-0"
          :display-icon="true"
        >
          <p v-for="message in toast.messages" :key="message">
            {{ message }}
          </p>
          <button
            v-if="toast.action"
            type="button"
            class="block underline"
            @click="handleActionClick(toast)"
          >
            {{ toast.action.text }}
          </button>
          <button
            v-if="toast.showCloseButton"
            type="button"
            class="absolute right-0 top-0 !mt-0 size-8"
            aria-label="cliquer pour cacher ce message"
            @click="removeToast(toast.id)"
          >
            <svg-icon
              class="absolute right-2 top-2 size-3 fill-black"
              name="icon-close"
            />
          </button>
        </banner-message>
      </div>
    </transition-group>
  </div>
</template>

<script>
import gsap from 'gsap';

import BannerMessage from '@style-guide/components/banner_message/BannerMessage';
import SvgIcon from '@style-guide/base/svg_icon/SvgIcon.vue';

export default {
  components: {
    BannerMessage,
    SvgIcon,
  },

  data() {
    return {
      isShowing: false,
      toasts: [],
    };
  },

  mounted() {
    if (window.IPSO_GLOBALS) {
      window.IPSO_GLOBALS.addToastMessage = this.addToast;
      window.IPSO_GLOBALS.removeToastMessage = this.removeToast;
    }
  },

  methods: {
    enter: async function (el, done) {
      if (this.isShowing !== true) {
        this.isShowing = true;

        await this.$nextTick();
      }

      await gsap.to(el, {
        duration: 0.3,
        height: 'auto',
        opacity: 1,
        ease: 'power2.inOut',
      });

      done();
    },

    leave: async function (el, done) {
      await gsap.to(el, {
        duration: 0.3,
        height: '0',
        opacity: 0,
        ease: 'power2.inOut',
      });

      if (this.toasts.length === 0) {
        this.isShowing = false;

        await this.$nextTick();
      }

      done();
    },

    addToast({
      type = 'info',
      duration = Infinity,
      messages = [],
      message,
      action = {},
      showCloseButton = false,
      id,
    } = {}) {
      if (messages.length === 0 && message) {
        messages.push(message);
      }

      if (!id) {
        id = Date.now() + messages[0];
      }

      const toast = {
        messages,
        type,
        id,
        showCloseButton: showCloseButton || duration === Infinity,
      };

      const { text = 'en savoir plus', callback } = action;

      if (callback) {
        toast.action = {
          text,
          callback,
        };
      }

      this.toasts.push(toast);

      if (duration === Infinity) {
        return false;
      }

      window.setTimeout(() => {
        this.removeToast(id);
      }, duration);
    },

    handleActionClick(toast) {
      if (toast.action.callback) {
        toast.action.callback();

        this.removeToast(toast.id);
      }
    },

    removeToast(id) {
      this.toasts = this.toasts.filter((toast) => toast.id !== id);
    },
  },
};
</script>
