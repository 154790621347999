import $ from 'jquery';

const SCROLL_NAV_SELECTOR = 'nav[data-ipso-scroll-nav]';
const SCROLL_NAV_IN_VIEW_SECTION_CLASS = 'border-blue font-bold';
const SCROLL_NAV_OUT_OF_VIEW_SECTION_CLASS = 'border-white';

export default () => {
  const $scrollNavMenus = $(SCROLL_NAV_SELECTOR);

  $scrollNavMenus.each(function () {
    const $scrollNavMenu = $(this);
    const $scrollNavLinks = $scrollNavMenu.children('a');

    function updateSelectedSection() {
      $scrollNavLinks.each(function () {
        const $link = $(this);
        const scrollPos = $(document).scrollTop();
        const $target = $($link.attr('href'));
        const refPos = $target.offset().top - $scrollNavMenu.height();
        const refHeight = $target.height();
        if (refPos <= scrollPos && refPos + refHeight > scrollPos) {
          $scrollNavLinks.removeClass(SCROLL_NAV_IN_VIEW_SECTION_CLASS);
          $scrollNavLinks.addClass(SCROLL_NAV_OUT_OF_VIEW_SECTION_CLASS);
          $link.removeClass(SCROLL_NAV_OUT_OF_VIEW_SECTION_CLASS);
          $link.addClass(SCROLL_NAV_IN_VIEW_SECTION_CLASS);
        }
      });
    }

    updateSelectedSection();
    $(window).on('scroll resize', updateSelectedSection);

    $scrollNavLinks.each(function () {
      const $link = $(this);
      $link.click(() => {
        const $target = $($link.attr('href'));
        $('html, body').animate(
          {
            //IMPORTANT: One extra pixel to pass the line!
            scrollTop: $target.offset().top - $scrollNavMenu.height() + 1,
          },
          500,
        );
        return false;
      });
    });
  });
};
