import $ from 'jquery';

export const FIELD_AUTOFOCUS_SELECTOR = 'data-ipso-field-autofocus';

export default () => {
  const $fields = $(`[${FIELD_AUTOFOCUS_SELECTOR}]`);
  if (!$fields.length) {
    return false;
  }

  $fields.each((i, el) => new FieldAutoFocus(el));
};

export class FieldAutoFocus {
  constructor(el) {
    this.onpaste = this.onpaste.bind(this);
    this.autocomplete = this.autocomplete.bind(this);

    this.input = el;
    this.$input = $(el);
    if (this.$input.attr('type') === 'text' && this.$input.attr('pattern')) {
      this.$input.on('input', (event) => {
        this.autofocus(event, this.$input.val(), this.$input.attr('pattern'));
      });
      this.input.addEventListener('keydown', this.ondeleteAutofocus);
      this.input.addEventListener('paste', this.onpaste);
      this.input.addEventListener('focus', this.selectOnFocus);

      if (this.input.autocomplete === 'one-time-code') {
        this.input.addEventListener('input', this.autocomplete);
      }
    }
  }

  autofocus = function (event, val, pattern) {
    const re = new RegExp(pattern);
    if (re.test(val)) {
      // the value is correct, it is saved as the last correct previous value
      event.target.dataset.previousValue = event.target.value;
      this.$input.next().length > 0
        ? this.$input.next().focus()
        : this.$input[0].select();
    } else {
      this.$input[0].value = this.$input[0].dataset.previousValue;
      this.$input[0].select();
    }
  };

  selectOnFocus(event) {
    // keep the correct value before any change;
    // it can then be restore later if an incorrect value is passed.
    event.target.dataset.previousValue = event.target.value;
    event.target.select();
  }

  ondeleteAutofocus(event) {
    if (event.key === 'Backspace') {
      event.preventDefault();

      event.target.value = '';
      event.target.dataset.previousValue = '';
      const previous = event.target.previousElementSibling;
      previous && previous.focus();
    }
  }

  onpaste(event) {
    event.preventDefault();
    event.stopPropagation();

    const pastedData = event.clipboardData.getData('text');
    const inputs = event.target.parentNode.getElementsByTagName('input');
    this.fillAll(inputs, pastedData);
  }

  autocomplete(event) {
    const value = event.target.value;
    const inputs = event.target.parentNode.getElementsByTagName('input');
    this.fillAll(inputs, value);
  }

  fillAll(inputs, value) {
    // create a regexp pattern from joined input.pattern
    const pattern = Array.from(inputs)
      // backslash are treated as escape character when using join
      .map((input) => input.pattern.replace(/\\/, '\\'))
      .join('');

    const re = new RegExp(`^${pattern}$`);
    if (!re.test(value)) {
      return;
    }

    value.split('').forEach((digit, index) => {
      if (inputs[index]) {
        inputs[index].value = digit;
      }
    });
    inputs[value.length - 1].focus();
  }
}
