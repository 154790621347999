<template>
  <div
    ref="mapElem"
    class="flex w-full content-center items-start"
    :class="defaultCss"
  />
</template>
<script>
import Sentry from '@util/sentry';

import { colors, fontFamily, fontSize } from '@util/theme';
import { DefaultRenderer, MarkerClusterer } from '@googlemaps/markerclusterer';
import defaultCssClass from '@util/vue-default-css-class';
import {
  getMapStyleIdForZoomLevel,
  mapStyles,
} from '@style-guide/components/map/google-map-styles';

import { useGoogleMapsApi } from '@composables/use-google-maps-api';

const icons = {
  GREY: '/static/img/maps/pin-medical-center.png',
  BLUE: '/static/img/maps/pin-medical-center-blue.png',
};

const defaultOptions = {
  center: {
    lat: 0,
    lng: 0,
  },
  zoom: 11,

  mapTypeControl: false,
  streetViewControl: false,
  rotateControl: false,
  scrollwheel: false,
};

const defaultLabelOptions = {
  color: colors.blue.DEFAULT,
  fontFamily: fontFamily.sans.join(', '),
  fontSize: fontSize.base[0],
  fontWeight: 'bold',
  className: 'px-3 py-1 bg-white bg-opacity-50 rounded-full',
};

export default {
  props: {
    lat: {
      type: Number,
    },
    lng: {
      type: Number,
    },
    zoom: {
      type: Number,
    },
    publicHostName: {
      type: String,
    },
    selectedMedicalCentersSlugs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    medicalCenters: {
      type: Array,
      default: () => {
        return [];
      },
    },
    apiKey: {
      type: String,
    },
    iconSelectedSize: {
      type: Number,
      default: 64,
    },
    iconDefaultSize: {
      type: Number,
      default: 48,
    },
    clusterIconSizeCountFactor: {
      type: Number,
      default: 0.1,
    },
    clusterIconSizeSelectionFactor: {
      type: Number,
      default: 0.2,
    },
    alwaysDisplayMarkerLabel: {
      type: Boolean,
      default: false,
    },
    clusterIsSelectable: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['select-medical-center'],

  setup() {
    const nonce = document.getElementById('nonce-script-tag').nonce;

    const { getGMapsApi, googleMapsApi } = useGoogleMapsApi({
      nonce,
    });

    return { getGMapsApi, googleMapsApi };
  },

  data() {
    return {
      map: null,
      markers: {},
      clusters: {},
    };
  },

  computed: {
    defaultCss() {
      return defaultCssClass(this, 'h-full w-full rounded-inherit');
    },

    isDirty() {
      return !(this.lat && this.lng);
    },

    mapOptions() {
      return {
        ...defaultOptions,
        zoomControlOptions: {
          position: this.googleMapsApi.maps.ControlPosition.TOP_LEFT,
        },
        center: {
          lat: this.lat,
          lng: this.lng,
        },
        zoom: this.zoom,
        mapTypeControlOptions: {
          mapTypeIds: [
            'roadmap',
            'satellite',
            'hybrid',
            'terrain',
            'styled_map',
          ],
        },
      };
    },
  },

  watch: {
    isDirty: function () {
      if (!this.isDirty) {
        this.drawMap();
      }
    },

    medicalCenters: function () {
      this.setMarkers();
    },

    selectedMedicalCentersSlugs: function () {
      this.updateMarkers();
    },
  },

  mounted() {
    this.sentry = Sentry.getInstance();

    this.drawMap();
  },

  methods: {
    isSelected(medicalCenter) {
      return (
        medicalCenter.isSelected ||
        this.selectedMedicalCentersSlugs.includes(medicalCenter.slug)
      );
    },

    drawMap() {
      if (!this.isDirty) {
        const mapElem = this.$refs['mapElem'];

        return this.getGMapsApi().then((googleMapsApi) => {
          if (!this.map) {
            this.map = new googleMapsApi.maps.Map(mapElem, this.mapOptions);
            this.configureMapStyles();
            this.setMapStyle();
            this.setMarkers();

            googleMapsApi.maps.event.addListener(
              this.map,
              'zoom_changed',
              this.zoomChange,
            );
          } else {
            this.map.setOptions(this.mapOptions);
          }
        });
      }
    },

    configureMapStyles() {
      Object.entries(mapStyles).forEach(([styleId, style]) => {
        this.map.mapTypes.set(
          styleId,
          new this.googleMapsApi.maps.StyledMapType(style),
        );
      });
    },

    setMapStyle() {
      const mapTypeId = getMapStyleIdForZoomLevel(this.map.getZoom());
      this.map.setMapTypeId(mapTypeId);
    },

    zoomChange() {
      this.setMapStyle();
      this.updateMarkers();
    },

    clearMarkers() {
      Object.entries(this.markers).forEach(([medicalCenterSlug]) => {
        this.markers[medicalCenterSlug].setMap(null);
      });
      this.markers = {};
    },

    setMarkers() {
      if (!this.googleMapsApi) {
        return;
      }

      this.clearMarkers();
      let markers = [];
      this.medicalCenters.forEach((medicalCenter) => {
        markers.push(this.addMarker(medicalCenter));
      });
      new MarkerClusterer({
        markers,
        map: this.map,
        renderer: this.getMarkerClusterRenderer(),
      });
    },

    updateMarkers() {
      this.medicalCenters.forEach((medicalCenter) => {
        if (medicalCenter.slug in this.markers) {
          let marker = this.markers[medicalCenter.slug];
          marker.setIcon(this.medicalCenterMarkerIcon(medicalCenter));
          marker.setLabel(this.medicalCenterMarkerLabel(medicalCenter));
        }

        if (medicalCenter.slug in this.clusters) {
          let cluster = this.clusters[medicalCenter.slug];
          if (cluster.marker) {
            cluster.marker.setIcon(this.clusterMarkerIcon(cluster));
            cluster.marker.setLabel(this.clusterMarkerLabel(cluster));
          }
        }
      });
    },

    iconDef(iconUrl, size) {
      return {
        url: this.publicHostName + iconUrl,
        scaledSize: new this.googleMapsApi.maps.Size(size, size),
        labelOrigin: new this.googleMapsApi.maps.Point(size / 2, size + 8),
      };
    },

    medicalCenterZoneLabel(medicalCenter) {
      return medicalCenter.zone[0].toUpperCase() + medicalCenter.zone.slice(1);
    },

    medicalCenterMarkerIcon(medicalCenter) {
      const icon = medicalCenter.isRecruiting ? icons.GREY : icons.BLUE;

      return this.iconDef(
        icon,
        this.isSelected(medicalCenter)
          ? this.iconSelectedSize
          : this.iconDefaultSize,
      );
    },

    medicalCenterMarkerTitle(medicalCenter) {
      if (medicalCenter.isRecruiting) {
        return `ipso ${medicalCenter.name} 🚧`;
      }

      return `ipso ${medicalCenter.name}`;
    },

    medicalCenterMarkerLabel(medicalCenter) {
      const textColor = medicalCenter.isRecruiting
        ? colors.gray.darker
        : colors.blue.DEFAULT;

      const defaultLabel = {
        text: this.medicalCenterMarkerTitle(medicalCenter),
        ...defaultLabelOptions,
        color: textColor,
      };

      if (this.isSelected(medicalCenter)) {
        return {
          ...defaultLabel,
          fontSize: fontSize['edt-base'][0],
          // className: 'px-3 py-1 bg-white rounded-full shadow',
        };
      }

      if (this.map.getZoom() < 8) {
        let text = `${this.medicalCenterZoneLabel(medicalCenter)} - 1 cabinet`;

        if (medicalCenter.isRecruiting) {
          text = `${text} 🚧`;
        }

        return {
          text,
          ...defaultLabelOptions,
          color: textColor,
        };
      } else {
        return this.alwaysDisplayMarkerLabel ? defaultLabel : '';
      }
    },

    overMarker(slugs) {
      const vm = this;
      return function () {
        slugs.forEach((slug) => {
          vm.$emit('select-medical-center', slug, true);
        });
      };
    },

    outMarker(slugs) {
      const vm = this;
      return function () {
        slugs.forEach((slug) => {
          vm.$emit('select-medical-center', slug, false);
        });
      };
    },

    registerMarkerEvents(marker, slugs) {
      marker.addListener('mouseover', this.overMarker(slugs));
      marker.addListener('mouseout', this.outMarker(slugs));
      marker.addListener('touchstart', this.overMarker(slugs));
      marker.addListener('touchend', this.outMarker(slugs));
    },

    addMarker(medicalCenter) {
      if (!this.googleMapsApi) {
        return;
      }

      const position = new this.googleMapsApi.maps.LatLng(
        medicalCenter.lat,
        medicalCenter.lng,
      );

      const icon = this.medicalCenterMarkerIcon(medicalCenter);
      const title = this.medicalCenterMarkerTitle(medicalCenter);
      const label = this.medicalCenterMarkerLabel(medicalCenter);

      const marker = new this.googleMapsApi.maps.Marker({
        position,
        icon,
        title,
        label,
        map: this.map,
        type: 'ipso',
      });

      this.registerMarkerEvents(marker, [medicalCenter.slug]);

      marker.medicalCenter = medicalCenter;
      this.markers[medicalCenter.slug] = marker;
      return marker;
    },

    addClusterMarker(cluster) {
      if (!this.googleMapsApi) {
        return;
      }

      const title = this.clusterMarkerTitle(cluster, {
        medicalCentersList: true,
      });

      const icon = this.clusterMarkerIcon(cluster);
      const label = this.clusterMarkerLabel(cluster);
      const marker = new this.googleMapsApi.maps.Marker({
        position: cluster.position,
        icon: icon,
        title: title,
        label: label,
        // adjust zIndex to be above other markers
        zIndex: 50 + cluster.count,
      });

      const markerSlugs = this.clusterMedicalCenters(cluster).map(
        (medicalCenter) => medicalCenter.slug,
      );

      if (this.clusterIsSelectable) {
        this.registerMarkerEvents(marker, markerSlugs);
      }

      markerSlugs.forEach((slug) => {
        this.clusters[slug] = cluster;
      });
      return marker;
    },

    clusterMedicalCenters(cluster) {
      return cluster.markers.map((marker) => marker.medicalCenter);
    },

    clusterMarkerTitle(
      cluster,
      options = {
        zone: true,
        medicalCentersCount: true,
        medicalCentersList: false,
      },
    ) {
      let titleParts = [];

      if (options.zone) {
        const clusterZones = new Set(
          this.clusterMedicalCenters(cluster).map((mc) =>
            this.medicalCenterZoneLabel(mc),
          ),
        );

        titleParts.push(Array.from(clusterZones).join(', '));
      }

      if (options.medicalCentersCount) {
        titleParts.push(
          `${cluster.count} cabinet${cluster.count > 1 ? 's' : ''}`,
        );
      }

      if (titleParts.length > 0) {
        titleParts = [titleParts.join(' - ')];
      }

      if (options.medicalCentersList) {
        let medicalCenterNameStr = this.clusterMedicalCenters(cluster)
          .filter((mc) => this.isSelected(mc))
          .map((mc) => `ipso ${mc.name}`)
          .join(', ');
        titleParts.push(`${medicalCenterNameStr}`);
      }

      return titleParts.join(' : ');
    },

    selectedMedicalCentersInCluster(cluster) {
      return this.clusterMedicalCenters(cluster).filter((medicalCenter) =>
        this.isSelected(medicalCenter),
      );
    },

    clusterIncludesSelectedMedicalCenter(cluster) {
      return this.selectedMedicalCentersInCluster(cluster).length > 0;
    },

    clusterMarkerIcon(cluster) {
      const size =
        this.iconDefaultSize *
        (1 +
          0.1 * cluster.count +
          (this.clusterIncludesSelectedMedicalCenter(cluster) ? 0.2 : 0));
      return {
        ...this.iconDef(icons.BLUE, size),
        labelOrigin: new this.googleMapsApi.maps.Point(size / 2, size + 8),
      };
    },

    clusterMarkerLabel(cluster) {
      const selectedInClusterCount =
        this.selectedMedicalCentersInCluster(cluster).length;
      const selected = selectedInClusterCount > 0;
      const title = this.clusterMarkerTitle(cluster, {
        zone: !selected,
        medicalCentersList: selected,
        medicalCentersCount: !selected,
      });

      let defaultLabel = {
        text: title,
        ...defaultLabelOptions,
      };

      if (selectedInClusterCount !== 1) {
        return defaultLabel;
      } else {
        return {
          ...defaultLabel,
          fontSize: fontSize['edt-base'][0],
        };
      }
    },

    getMarkerClusterRenderer() {
      let renderer = new DefaultRenderer();

      renderer.render = (cluster) => {
        return this.addClusterMarker(cluster);
      };

      return renderer;
    },
  },
};
</script>
